import React, {useRef, useState} from 'react';
import styles from './registration-form.modules.css';
import {PhoneInput} from "./phone-input.jsx";
import {phoneCodes} from "./phone-codes";
import {PasswordInput} from "./password-input.jsx";
import {Alert} from './alert.jsx';
import {classnames} from "./utils/classnames";
import {checkPhoneNumber} from "./utils/phone-handlers";
import {checkPassword, checkPasswordLen, checkPasswordLetters} from "./utils/password";
import {sendForm} from "./utils/send-form";
import {sendCalltouch} from './calltouch';

export const RegForm = ({onNext}) => {
  const [formState, setFormState] = useState({
    country: phoneCodes[0],
    phone: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
    name: {
      value: '',
      error: '',
    },
    email: {
      value: '',
      error: '',
    },
  });
  const refEmail = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const enterField = (value, field) => {
    setFormState(prevState => ({...prevState, [field]: {value: value, error: ''}}))
  }

  const selectCountry = (country) => {
    setFormState(prevState => ({...prevState, country: country}))
  }

  const startRegister = async () => {
    setIsLoading(true);

    const body = {
      phone: formState.country.code + formState.phone.value,
      password: formState.password.value,
      name: formState.name.value,
      email: formState.email.value,
      referral_code: '',
    }

    const response = await sendForm(body);

    if (!response.ok) {
      let errorText = '';
      let errorTextEmail = '';

      if (response.status === 400) {
        const responseJson = await response.json();

        if (responseJson) {
          if (responseJson['status'] === 'USER_ALREADY_EXISTS') {
            errorText = 'Пользователь с таким номером телефона уже есть';
          } else if (responseJson['status'] === 'FORBIDDEN_EMAIL') {
            errorTextEmail = 'Некорректный адрес электронной почты';
          } else {
            errorText = responseJson.message;
          }
        } else {
          errorText = 'Непредвиденная ошибка, попробуйте позже';
        }
      } else {
        errorText = 'Непредвиденная ошибка, попробуйте позже';
      }

      setIsLoading(false);
      setFormState(prevState => ({
        ...prevState,
        phone: {
          ...prevState.phone,
          error: errorText,
        },
        email: {
          ...prevState.email,
          error: errorTextEmail,
        }
      }))
    } else {
      window.gtag && window.gtag('event', 'reg_form_sent');
      window.ym && window.ym(65686081, 'reachGoal', 'form_submit');

      try {
        await sendCalltouch({
          phoneNumber: body.phone,
          email: body.email,
          fio: body.name,
          subject: 'Форма регистрации',
          requestUrl: location.href,
          sessionId: window.ct && window.ct('calltracking_params', 'pqbqfabs').sessionId
        })
        onNext(body.phone);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (window.dataLayer !== undefined) {
      window.dataLayer.push({'event': 'gaEvent', 'category': 'registration', 'action': 'click'});
    }

    const phoneValue = `${formState.country.code}${formState.phone.value}`;
    const isCorrectPhone = checkPhoneNumber(phoneValue);
    const isCorrectEmail = !!formState.email.value.length;
    const isCorrectPassword = checkPassword(formState.password.value);
    const isCorrectName = !!formState.name.value;
    let _formState = {...formState};

    if (!isCorrectPhone) {
      _formState = {
        ..._formState,
        phone: {..._formState.phone, error: 'Чтобы зарегистрироваться, заполните все поля'}
      }
    }

    if (!isCorrectPassword) {
      const isCorrectPasswordLen = checkPasswordLen(_formState.password.value);
      const isCorrectPasswordLetters = checkPasswordLetters(_formState.password.value);

      if (!isCorrectPasswordLen) {
        _formState = {
          ..._formState,
          password: {..._formState.password, error: 'Должно быть минимум 6 знаков'}
        }
      } else if (!isCorrectPasswordLetters) {
        _formState = {
          ..._formState,
          password: {..._formState.password, error: 'Только латиница, цифры и символы'}
        }
      }
    }

    if (!isCorrectEmail) {
      _formState = {
        ..._formState,
        email: {..._formState.email, error: 'Чтобы зарегистрироваться, заполните все поля'}
      }
    }

    if (!isCorrectName) {
      _formState = {
        ..._formState,
        name: {..._formState.name, error: 'Чтобы зарегистрироваться, заполните все поля'}
      }
    }

    setFormState(_formState);

    if (isCorrectPhone && isCorrectEmail && isCorrectPassword && isCorrectName) {
      startRegister();
    }
  }

  return (
    <div>
      <form className={styles.registrationForm} onSubmit={onSubmit}>
        <div>
          <label>Номер телефона</label>
          <PhoneInput
            isError={!!formState.phone.error}
            phone={formState.phone.value}
            setPhone={e => enterField(e, 'phone')}
            country={formState.country}
            setCountry={selectCountry}
          />
          {!formState.phone.error &&
            <Alert
              type='info'
              text='Отправим SMS-код для подтверждения номера'
            />
          }
          {formState.phone.error &&
            <Alert
              type='error'
              text={formState.phone.error}
            />
          }
        </div>
        <div>
          <label>Пароль</label>
          <PasswordInput
            isError={!!formState.password.error}
            password={formState.password.value}
            setPassword={e => enterField(e, 'password')}
          />
          {!formState.password.error &&
            <Alert
              type='info'
              text='Латиница, цифры и символы. Минимум 6 знаков'
            />
          }
          {formState.password.error &&
            <Alert
              type='error'
              text={formState.password.error}
            />
          }
        </div>
        <div>
          <label>Имя</label>
          <input
            className={classnames(
              !!formState.name.error && styles.inputError
            )}
            type='text'
            placeholder='Иван Анабар'
            inputMode='text'
            value={formState.name.value}
            onChange={e => enterField(e.target.value, 'name')}
            autoComplete='off'
          />
          {formState.name.error &&
            <Alert
              type='error'
              text={formState.name.error}
            />
          }
        </div>
        <div>
          <label>Электронная почта</label>
          <input
            className={classnames(
              !!formState.email.error && styles.inputError
            )}
            ref={refEmail}
            type='email'
            placeholder='ivan@mail.ru'
            inputMode='email'
            value={formState.email.value}
            onChange={e => enterField(e.target.value, 'email')}
            autoComplete='off'
          />
          {!formState.email.error &&
            <Alert
              type='info'
              text='Для информации о важных изменениях сервиса'
            />
          }
          {formState.email.error &&
            <Alert
              type='error'
              text={formState.email.error}
            />
          }
        </div>
        <button
          className={styles.registrationFormButton}
          disabled={isLoading}
        >
          Получить код
        </button>
      </form>
      <p className={styles.privacy}>
        Регистрируясь, вы соглашаетесь <a href="/docs/privacy" target="_blank">на обработку персональных данных </a>
        и <a href="/docs/nda" target="_blank" >защиту конфиденциальной информации</a>
      </p>
    </div>
  )
}
