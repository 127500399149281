const ruPhoneNumberDigitsCnt = 11;
const kzPhoneNumberDigitsCnt = 11;

export const checkPhoneNumber = number => {
  const clearNumber = number
    .replace(/[()_-]*/g, "")
    .replace("+", "")
    .replace(" ", "");
  if (
    new RegExp(`^79\\d{${ruPhoneNumberDigitsCnt - 2}}$`).test(clearNumber) || //RU
    /^375(\d{7}|\d{9})$/.test(clearNumber) || //BY
    /^374\d{8}$/.test(clearNumber) || //AM
    new RegExp(`^7\\d{${kzPhoneNumberDigitsCnt - 1}}$`).test(clearNumber) || //KZ
    /^62\d{9,14}$/.test(clearNumber)
  ) {
    //ID
    return clearNumber;
  }
  return false;
};

export const correctPhoneNumber = (
  number,
  cleaningStr = [],
  lengthLimit = undefined
) => {
  if (!number) {
    return number;
  }
  let numberWithoutCode = number;
  for (let str of cleaningStr) {
    if (number.startsWith(str)) {
      numberWithoutCode = numberWithoutCode.slice(str.length);
      break;
    }
  }

  if (lengthLimit) {
    const digitsCnt = numberWithoutCode.replaceAll(/\s|-|_/g, "").length;
    const extraDigitsCnt = digitsCnt - lengthLimit;
    if (extraDigitsCnt > 0) {
      numberWithoutCode = numberWithoutCode.slice(0, -extraDigitsCnt);
    }
  }
  return numberWithoutCode;
};
