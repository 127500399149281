import React from 'react';
import * as ReactDOM from 'react-dom';
import {RegistrationFrom} from './registration-form.jsx';

export const initRegForm = (id) => {
  const element = document.getElementById(id);

  if (element) {
    ReactDOM.render(<RegistrationFrom/>, element);
  }
}

window.initRegForm = initRegForm;
