export const sendCalltouch = async data => {
  const formBody = [];

  for (const property in data) {
    if (data[property]) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
  }

  return await fetch(
    "https://api.calltouch.ru/calls-service/RestAPI/requests/64183/register/",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      },
      body: formBody.join("&")
    }
  );
};
