import React, {useState} from "react";
import styles from './registration-form.modules.css';
import showIcon from './assets/images/show-v2.svg';
import hideIcon from './assets/images/hide-v2.svg';
import {classnames} from "./utils/classnames";

export const PasswordInput = ({password, setPassword, isError}) => {
  const [show, setShow] = useState(false);

  return (
    <div className={styles.passwordInput}>
      <input
        className={classnames(
          isError && styles.inputError
        )}
        type={show ? 'text' : 'password'}
        placeholder='Придумайте пароль'
        inputMode='text'
        value={password}
        onChange={e => setPassword(e.target.value)}
        autoComplete='off'
      />
      <div className={styles.passwordInputShow} onClick={() => setShow(!show)}>
        <img src={show ? hideIcon : showIcon} />
      </div>
    </div>
  )
}
