import styles from "./registration-form.modules.css";
import {MaskedInput, createDefaultMaskGenerator} from "react-hook-mask";
import React from "react";
import { PhoneSelect } from "./phone-select.jsx";
import {classnames} from "./utils/classnames";

export const PhoneInput = ({phone, setPhone, country, setCountry, isError}) => {
  const mask = country.mask;
  const maskGenerator = createDefaultMaskGenerator(mask);

  return (
    <div className={classnames(
      styles.phoneInputContainer,
      isError && styles.inputError
    )}>
      <PhoneSelect
        country={country}
        setCountry={setCountry}
      />
      <MaskedInput
        className={styles.phoneInput}
        maskGenerator={maskGenerator}
        value={phone}
        onChange={setPhone}
        placeholder={mask}
      />
    </div>
  )
}
