import React, {useEffect, useState} from 'react';
import styles from './registration-form.modules.css';
import {classnames} from "./utils/classnames";
import {Alert} from "./alert.jsx";
import backIcon from './assets/images/back.svg';
import {sendCode} from "./utils/send-code";
import {Timer} from "./timer.jsx";
import {submitCode} from "./utils/submit-code";

const errorsStatusTexts = {
  wrongCode: 'WRONG_CODE',
  outdatedCode: 'CODE_EXPIRED',
  tooManyAttempts: 'TOO_MANY_VERIFICATION_ATTEMPTS',
};
export const VerifyPhone = ({onPrev, phone=''}) => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showTimer, setShowTimer] = useState(false);

  const getCodeStatus = async () => {
    const response = await sendCode(phone);

    if (!response.ok) {
      setError('Непредвиденная ошибка, попробуйте позже');
    } else {
      setShowTimer(true);
    }
  }

  useEffect(() => {
    getCodeStatus();
  }, []);

  const onSubmitCode = async ({phone, code}) => {
    setIsLoading(true);
    const response = await submitCode({phone, code});

    if (!response.ok) {
      let errorText = 'Непредвиденная ошибка, попробуйте позже'

      if (response.status === 400) {
        const responseJson = await response.json();
        const status = responseJson.status;

        if (responseJson) {
          switch (status) {
            case errorsStatusTexts.wrongCode:
              errorText = 'Неверный код. Попробуйте ещё раз или получите новый';
              break;
            case errorsStatusTexts.outdatedCode:
              errorText = 'Код устарел, получите новый';
              break;
            case errorsStatusTexts.tooManyAttempts:
              errorText = 'Слишком много попыток ввода кода. Попробуйте получить новый через 10 минут';
              break;
          }
        }
      }

      setError(errorText);
      setIsLoading(false);
    } else {
      window.location.replace('/welcome');
    }

  }

  const enterCode = (value) => {
    setError('');
    setCode(value);
    if (value.length === 6) {
      onSubmitCode({phone, code: value});
    }
  }

  const onSubmit = e => {
    e.preventDefault();
    onSubmitCode({phone, code});
  }

  return (
    <div className={styles.verifyPhone}>
      <button className={styles.backButton} onClick={onPrev}>
        <img src={backIcon}/>
        Назад
      </button>
      <div className={styles.verifyPhoneTitle}>Подтверждение номера</div>
      <div className={styles.verifyPhoneSubTitle}>SMS-код отправлен на номер +{phone}</div>
      <form className={styles.verifyPhoneForm} onSubmit={onSubmit}>
        <div>
          <div className={styles.verifyPhoneFormLabel}>
            <label>Код из SMS</label>
            {showTimer && <Timer onClick={() => getCodeStatus()}/>}
          </div>
          <input
            className={classnames(
              styles.codeInput,
              !!error && styles.inputError
            )}
            value={code}
            type='number'
            inputMode='numeric'
            onChange={e => enterCode(e.target.value)}
            autoComplete='off'
          />
          {error &&
            <Alert
              type='error'
              text={error}
            />
          }
        </div>
        <button
          disabled={isLoading}
        >
          Подтвердить и войти в Анабар
        </button>
      </form>
    </div>
  )

}
