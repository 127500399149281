import React from 'react';
import { useTimer } from 'react-timer-hook';
import styles from './registration-form.modules.css';

export const Timer = ({onClick}) => {
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 300);

  const {
    seconds,
    minutes,
    isRunning,
  } = useTimer({ expiryTimestamp });

  const text = isRunning ? `Получить новый через ${minutes}:${seconds}` : 'Получить новый';

  return(
    <button
      className={styles.timer}
      onClick={onClick}
      disabled={isRunning}
    >
      {text}
    </button>
  )
}
