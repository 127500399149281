export const sendForm = async body => {
  const parameters = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term",
    "yclid",
    "referrer",
    "last_referrer"
  ];

  for (const parameterName of parameters) {
    const parameter = localStorage.getItem(parameterName);
    if (parameter) {
      body[parameterName] = parameter.toString();
    }
  }

  return await fetch("/signup/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  });
};
