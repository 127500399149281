import RU from "./assets/images/RU.svg";
import BY from "./assets/images/BY.svg";
import KZ from "./assets/images/KZ.svg";
import AM from "./assets/images/AM.svg";
import UZ from "./assets/images/UZ.svg";
import KG from "./assets/images/KG.svg";

export const phoneCodes = [
  { label: "Россия", code: "7", icon: RU, mask: "999 999-99-99" },
  { label: "Беларусь", code: "375", icon: BY, mask: "99 999-99-99" },
  { label: "Казахстан", code: "7", icon: KZ, mask: "999 999-99-99" },
  { label: "Армения", code: "374", icon: AM, mask: "99-999-999" },
  { label: "Узбекистан", code: "998", icon: UZ, mask: "99-999-99-99" },
  { label: "Кыргызстан", code: "996", icon: KG, mask: "999-999-999" }
];
