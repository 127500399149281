export const submitCode = async ({ phone, code }) => {
  return await fetch("/registered", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      phone,
      code
    })
  });
};
