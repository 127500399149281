import React from 'react';
import styles from './registration-form.modules.css';
import {classnames} from "./utils/classnames";

export const Alert = ({text, type = 'info'}) => {
  return (
    <div className={classnames(
      styles.alert,
      type === 'error' && styles.alertError
    )}>
      {text}
    </div>
  )
}
