import styles from "./registration-form.modules.css";
import ArrowIcon from "./assets/images/select-arrow-v2.svg";
import React, {useRef, useState} from "react";
import { phoneCodes } from './phone-codes';
import {useOnClickOutside} from "./hooks/use-on-click-outside.jsx";

export const PhoneSelect = ({country, setCountry}) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setVisible(false));

  const onSelect = (country) => {
    setCountry(country);
    setVisible(false);
  }

  return (
    <div className={styles.countrySelect} ref={ref}>
      <div className={styles.countrySelectTrigger} onClick={() => setVisible(!visible)}>
        <img className={styles.countrySelectArrow} src={ArrowIcon} style={visible ? {transform: 'rotateX(180deg)'} : {}} alt='arrow'/>
        <img src={country.icon} alt={country.label}/>
        +{country.code}
      </div>
      {visible &&
        <div className={styles.countrySelectList}>
          <div className={styles.selectedCountryListTitle}>Код страны</div>
          {phoneCodes.map((country) => (
            <div className={styles.countrySelectListItem} onClick={() => onSelect(country)}>
              <img src={country.icon} alt={country.label}/>
              {country.label}
              <span>+{country.code}</span>
            </div>
          ))}
        </div>
      }
    </div>
  )
}
