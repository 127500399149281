import React, {useState} from 'react';
import {RegForm} from "./reg-form.jsx";
import {VerifyPhone} from "./verify-phone.jsx";
import styles from './registration-form.modules.css';

export const RegistrationFrom = () => {
  const [showCodeForm, setShowCodeForm] = useState(false);
  const [phone, setPhone] = useState('');

  return (
    <div className={styles.root}>
      {!showCodeForm && <RegForm onNext={(phone) => {
        setShowCodeForm(true);
        setPhone(phone);
      }}/>}
      {showCodeForm && <VerifyPhone phone={phone} onPrev={() => setShowCodeForm(false)}/>}
    </div>
  )

}
